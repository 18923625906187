/*
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0 | 20110126 | License: none (public domain)
 * slightly edited
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
table.we-custom-table,
table.we-custom-table tbody,
table.we-custom-table tfoot,
table.we-custom-table thead,
table.we-custom-table tr,
table.we-custom-table th,
table.we-custom-table td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
select,
textarea,
button,
:after,
:before {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;

  font-size: inherit;
  font: inherit;
  vertical-align: baseline;
}

sub,
sup {
  line-height: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

.touch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  line-height: 1.4;
}

.msie.mobile body {
  -ms-touch-action: none;
  touch-action: none;
}

iframe {
  width: 100%;
  height: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

.we-custom-table,
.ma-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

input,
textarea,
button,
select,
.contenteditable {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.15;
}

input[type='radio'] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

.touch input,
.touch textarea,
.touch select,
.touch .contenteditable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.touch input[type='file'] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.moz select {
  /* fixes outline on firefox select boxes */
  color: transparent !important;
  text-shadow: 0 0 0 #000 !important;
}

html {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
strong,
b,
.bold {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

a,
a:visited,
.branch-colored {
  color: #00a4fd;
  text-decoration: none;
}

.mouse a:hover {
  text-decoration: underline;
}

.touch a.active,
.touch a:not(.clickable):active {
  background-color: rgba(0, 0, 0, 0.06);
}

.mouse a,
.mouse button,
.mouse .clickable {
  cursor: pointer;
}

.mouse input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}
